/* Main entry point for JS vendors compilation */

//=require ./../../../../node_modules/jquery/dist/jquery.js
//=require vendors/bootstrap.js
//=require vendors/jquery.splitflap.js
//=require vendors/oldBrowser.js
//=require vendors/cookie.js
//=require ./../../../../node_modules/icheck/icheck.js
//=require ./../../../../node_modules/selectric/src/jquery.selectric.js
//=require ./../../../../node_modules/lity/dist/lity.js
//=require ./../../../../node_modules/aos/dist/aos.js
//=require ./../../../../node_modules/bxslider/dist/jquery.bxslider.min.js
//=require ./../../../../node_modules/tooltipster/dist/js/tooltipster.bundle.js
//=require ./../../../../node_modules/nicescroll/jquery.nicescroll.js
//=require ./../../../../node_modules/chart.js/dist/Chart.bundle.js
//=require ./../../../../node_modules/sticky-kit/dist/sticky-kit.js
//=require ./../../../../node_modules/jquery/dist/jquery.js
//=require ./../../../../node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker.js
//=require ./../../../../node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min.js
//=require vendors/tippy.all.min.js
//=require vendors/simplebar.min.js
